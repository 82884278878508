import axios from "axios";
import config from '@/config';
import store from "../store";
// import tokenAuth from "./tokenAuthenticationService";
import router from "../router";

const apiEndPoint = axios.create({
  baseURL: config.API_ENDPOINT,
  json: true,
});

const healthChecksEndPoint = axios.create({
  baseURL: config.HEALTH_CHECKS_ENDPOINT,
  json: true,
});

const appSheetToolsEndPoint = axios.create({
  baseURL: config.APPSHEET_TOOLS_ENDPOINT,
  json: true,
});
const hoursToolsEndPoint = axios.create({
  baseURL: config.HOURS_TOOLS_ENDPONT,
  json: true,
});
const metaDataToolsEndPoint = axios.create({
  baseURL: config.METADATA_TOOLS_ENDPOINT,
  json: true,
});
const toolsEndPoint = axios.create({
  baseURL: config.TOOLS_ENDPOINT,
  json: true,
});
const companyEndPoint = axios.create({
  baseURL: config.COMPANY_ENDPOINT,
  json: true,
});
const userEndPoint = axios.create({
  baseURL: config.USER_ENDPOINT,
  json: true,
});

function interceptorRequest(config) {
  const token = store.getters.token;
  // if (
  //   tokenAuth.accessTokenValidation(token, store.getters.tokenExpiryTime)
  // ) {
  config.headers.Authorization = `Bearer ${token}`;
  return config;
  // } else {
  //   return config;
  // }
}
function interceptorResponse(error) {
  console.log(error);
  store.dispatch("LogOut");
  router.push('/signin');
//   var refershObject = {
//     grant_type:"refresh_token",
//     refresh_token:store.getters.refreshToken,
//     user_name:store.getters.user
//   }
// //   store.commit("updateisTokenRefreshing", true);
//   tokenAuth.getAccessTokenByRefreshToken(
//     refershObject,
//     response => {          
//       store.commit("SET_TOKEN", response.access_token);
//       store.commit("SET_REFRESH_TOKEN", response.refresh_token);
//       store.commit("SET_TOKEN_EXPIRY_TIME", response.expires_at);
//     //   store.commit("updateisTokenRefreshing", false);
//       if (error.config.url) {
//         error.config.headers.Authorization = `Bearer ${response.access_token}`;
//         return axios.request(error.config);
//       } 
//     },
//     errorResponse => {
//       console.log(errorResponse);
//       store.dispatch("LogOut");
//       router.push('/signin');
//     }
//   );
}

apiEndPoint.interceptors.request.use(
  function(config) {
    let finalConfig = interceptorRequest(config);
    return finalConfig;
  },
  function(err) {
    return Promise.reject(err);
  }
);
apiEndPoint.interceptors.response.use(null, error => {
  if (error.config && error.response && error.response.status === 401) {
    interceptorResponse(error);
  }
  return Promise.reject(error);
});

healthChecksEndPoint.interceptors.request.use(
  function(config) {
    let finalConfig = interceptorRequest(config);
    return finalConfig;
  },
  function(err) {
    return Promise.reject(err);
  }
);
healthChecksEndPoint.interceptors.response.use(null, error => {
  if (error.config && error.response && error.response.status === 401) {
    interceptorResponse(error);
  }
  return Promise.reject(error);
});

appSheetToolsEndPoint.interceptors.request.use(
  function(config) {
    let finalConfig = interceptorRequest(config);
    return finalConfig;
  },
  function(err) {
    return Promise.reject(err);
  }
);
appSheetToolsEndPoint.interceptors.response.use(null, error => {
  if (error.config && error.response && error.response.status === 401) {
    interceptorResponse(error);
  }
  return Promise.reject(error);
});

hoursToolsEndPoint.interceptors.request.use(
  function(config) {
    let finalConfig = interceptorRequest(config);
    return finalConfig;
  },
  function(err) {
    return Promise.reject(err);
  }
);
hoursToolsEndPoint.interceptors.response.use(null, error => {
  if (error.config && error.response && error.response.status === 401) {
    interceptorResponse(error);
  }
  return Promise.reject(error);
});

metaDataToolsEndPoint.interceptors.request.use(
  function(config) {
    let finalConfig = interceptorRequest(config);
    return finalConfig;
  },
  function(err) {
    return Promise.reject(err);
  }
);
metaDataToolsEndPoint.interceptors.response.use(null, error => {
  if (error.config && error.response && error.response.status === 401) {
    interceptorResponse(error);
  }
  return Promise.reject(error);
});

toolsEndPoint.interceptors.request.use(
  function(config) {
    let finalConfig = interceptorRequest(config);
    return finalConfig;
  },
  function(err) {
    return Promise.reject(err);
  }
);
toolsEndPoint.interceptors.response.use(null, error => {
  if (error.config && error.response && error.response.status === 401) {
    interceptorResponse(error);
  }
  return Promise.reject(error);
});

companyEndPoint.interceptors.request.use(
  function(config) {
    let finalConfig = interceptorRequest(config);
    return finalConfig;
  },
  function(err) {
    return Promise.reject(err);
  }
);
companyEndPoint.interceptors.response.use(null, error => {
  if (error.config && error.response && error.response.status === 401) {
    interceptorResponse(error);
  }
  return Promise.reject(error);
});

userEndPoint.interceptors.request.use(
  function(config) {
    let finalConfig = interceptorRequest(config);
    return finalConfig;
  },
  function(err) {
    return Promise.reject(err);
  }
);
userEndPoint.interceptors.response.use(null, error => {
  if (error.config && error.response && error.response.status === 401) {
    interceptorResponse(error);
  }
  return Promise.reject(error);
});
export default{
  apiEndPoint,
  healthChecksEndPoint,
  appSheetToolsEndPoint,
  hoursToolsEndPoint,
  metaDataToolsEndPoint,
  toolsEndPoint,
  companyEndPoint,
  userEndPoint
};
