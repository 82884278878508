<template class="layout-toolbar">
  <v-app-bar app :dense="false">
    <!-- <breadcrumbs /> -->
    <!-- <v-text-field
      dense
      placeholder="Asset Search..."
      class="asset-search-box pt-3 pl-5"
      prepend-inner-icon="mdi-magnify"
    ></v-text-field> -->

    <v-spacer />
    <!-- <v-autocomplete
      autocomplete
      class="input-Field"
      label="Customer"
      outlined
      dense
      :items="customersList"
      item-value="referenceId"
      item-text="companyName"
      v-model="selectedCustomer"
      align-end
      return-object
      @change="updateCustomer"
    ></v-autocomplete> -->
      <!-- <slot name="widget-header-action"> -->
    <!-- <v-checkbox
      class="mt-5 pr-4"
      label="Set as default"
      :disabled="disabled || isMapRoute"
      v-model="defaultCustomer"
      @click="setDefaultCustomer()"
      v-if="!loading"
    ></v-checkbox> -->
     <!-- <div
        v-if="currentRoute !== '/activation-services'"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green" class="mr-4" v-on="on" v-bind="attrs"
              >mdi-information</v-icon
            >
          </template>
          <span>Page content is driven based on the company selected.</span>
        </v-tooltip>
      </div>
      <div v-else>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-4 ml-0"
              dark
              v-bind="attrs"
              v-on="on"
              color="#E1AD01"
              >mdi-alert</v-icon
            >
          </template>
          <span>Company controls are managed seperately on this page.</span>
        </v-tooltip>
      </div> -->
        <!-- <slot name="widget-header-action"> -->
    <!-- <v-autocomplete
      :menu-props="{ auto: true, bottom: true, 'offset-y': true }"
      autocomplete="off"
      outlined
      dense
      class="inputField company-dropdown"
      :items="parentcompaniesList"
      v-model="companySelected"
      return-object
      item-text="name"
      item-id="id"
      item-value="id"
      label="Customers"
      :disabled="isMapRoute"
      align-end
      auto
      @change="updateCompany"
      v-if="!updatingSelectedCompany"
    ></v-autocomplete> -->
        <!-- </slot> -->
      <!-- </slot> -->
      <slot name="widget-header-action">
        <v-autocomplete
          :menu-props="{ auto: true, bottom: true, 'offset-y': true }"
          autocomplete="off"
          outlined
          dense
          :disabled="roles!='Manager'"
          class="inputField company-dropdown mr-3"
          :items="oemsList"
          v-model="selectedOem"
          item-text="name"
          item-value="id"
          label="OEM"
          align-end
          auto
          @change="updateOem"
          v-if="roles=='Manager'"
        ></v-autocomplete>
        <span class="mr-3" v-if="roles=='User'"><b>{{selectedOemName}}</b></span>
      </slot>
    <profile />
  </v-app-bar>
</template>
<style scoped>
.select-type-report {
  width: 150px !important;
}
.btn-align {
  margin-right: 25px;
  font-size: 12px !important;
  text-transform: capitalize;
}
.plus-icon {
  font-size: 12px !important;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.align-right {
  text-align: right;
}
.textspacing {
  margin-top: 0px;
}
.asset-search-box {
  min-width: 150px;
  max-width: 250px;
  max-height: 40px;
}

.nav-icon {
  color: #383a3c !important;
}
.input-Field {
  height: 40px;
  max-width: 200px;
  margin-right: 3px !important;
}
.company-dropdown {
  height: 40px;
  max-width: 250px;
}
</style>
<script>
import { mapGetters } from "vuex";
import Profile from "../widget/AppProfile.vue";
// import apiService from "../api/api.service";
import EventBus from "../utils/EventBus";
import companyapi from "@/api/company.service";
import userService from "@/api/user.service";
export default {
  name: "TheLayoutToolbar",
  components: {
    //Breadcrumbs,
    Profile,
    // Notification
  },
  data: () => ({
    customersList: [],
    selectedCustomer: {},
    parentcompaniesList: [],
    companySelected: {},
    storedCRIds: [],
    defaultCustomer: true,
    disabled: true,
    currentRoute: null,
    isMapRoute: false,
    comapnyBasedOnId:[],
    appSheetIdforCompany:"",
    loading: false,
    updatingSelectedCompany: false,
    oemsList: [],
    selectedOem: "",
    selectedOemName: ""
  }),
   watch: {
    deep: true,
    immediate: true,
    $route:function() {
      this.handleRouter(true);
    }
  },
  computed: {
    ...mapGetters([
      "toolbarDense",
      "navbarShow",
      "roles",
      "reportvalue",
      "customerReferenceId",
      "referenceId",
      "companyName",
      "customerToken",
      "reportId",
      "loggedInReferenceId",
      "appsheetId",
      "customerIds",
      "toolIds",
      "oemCRIds",
      "oemCompanyId"
    ]),
  },
  // setLoggedInReferenceId
  created() {},
  mounted() {
    // this.CompanyList();
    this.oemCompanyList();
    this.handleRouter(false);
    //Check if reportID exists in store
    if (this.$store.getters.reportId != "") {
      //set the dropdown value to the store reportID.
      this.companySelected.id = this.$store.getters.reportId;
      //check if dropdown change value is default company value and enable checkbox based on that
      if (this.companySelected.id != this.$store.getters.referenceId) {
        this.disabled = false;
        this.defaultCustomer = false;
      } else {
        //disable checkbox as it is a default company
        this.disabled = true;
        this.defaultCustomer = true;
      }
    }
  },
  methods: {
  handleRouter(fromRouteChange){
      this.currentRoute = this.$route.fullPath;
      if (this.currentRoute == "/activation-services") {
        this.isMapRoute = true;
        this.$store.dispatch("setReportId", '');
        this.$store.dispatch("setCompanyName", '');
        this.companySelected = {};
        this.defaultCustomer = false;
      } else {
        // this.isMapRoute = false;
        if (fromRouteChange) {
          this.updatingSelectedCompany = true;
          if (this.$store.getters.referenceId && !this.$store.getters.reportId) {
            let selectedCompany = this.parentcompaniesList.filter(
              (c) => c.id == this.$store.getters.referenceId
            );
            if (selectedCompany.length == 1) {
              this.companySelected = {};
              this.companySelected["id"] = selectedCompany[0].id;
              this.companySelected["name"] = selectedCompany[0].name;
              this.disabled = true;
              this.defaultCustomer = true;
              this.$store.dispatch("setReportId", this.companySelected.id);
              this.$store.dispatch("setCompanyName", this.companySelected.name);
              if (this.currentRoute == "/") {
                EventBus.$emit("call-dashboard");
              } else if (this.currentRoute == "/audit-tool") {
                EventBus.$emit("call-audit-tool");
              }
            }
          } else if (!this.$store.getters.referenceId && !this.$store.getters.reportId) {
            if (this.currentRoute == "/audit-tool") {
                EventBus.$emit("call-audit-tool");
              }
          }
          this.isMapRoute = false;
          this.updatingSelectedCompany = false;
        } else {
          this.isMapRoute = false;
        }
      }
  },
    async oemCompanyList() {
      try {
        let response = await companyapi.getAllCustomers();
        if (response) {
          let oemsCompleteList = response.data.filter(
            (e) => e.isOEMCompany == true
          );
          this.oemsList = oemsCompleteList.filter(
                (c) => this.$store.getters.oemCompanyIdsList.includes(c.id)
              );
          this.selectedOem = this.oemCompanyId;
          let oem = this.oemsList.filter(
                (c) => c.id == this.selectedOem
              );
          this.selectedOemName = oem ? oem[0] ? oem[0].name : "" : "";
        }
      } catch (error) {
        //this.$toast.error("Unable to load the data" + error);
      }
    },
    async CompanyList() {
     let checkLiteTool = false;
     if(this.toolIds.includes('B4ADFA53-83C3-46FF-A158-F1CBDBB0E97A')){
       checkLiteTool = true;
     }else{
       checkLiteTool = false;
     }
      try {
        // let _this = this;
        this.loading = true;
        let response = await companyapi.getAllCustomers();
        if (response) {
          this.parentcompaniesList = response.data;
         
          if (this.roles == "User" && checkLiteTool) {
            // storedCRIds
             //Role is user & have unit360 tool
            this.storedCRIds = this.loggedInReferenceId.split(",");
            var newValues = [];
            for (var k = 0; k < this.storedCRIds.length; k++) {
              newValues.push({
                index: k,
                referenceId: this.storedCRIds[k],
              });
            }
            let tempdata = [];
            for (var i = 0; i < this.parentcompaniesList.length; i++) {
              for (var j = 0; j < newValues.length; j++) {
                if (this.parentcompaniesList[i].id == newValues[j].referenceId) {
                  tempdata.push(this.parentcompaniesList[i]);
                }
              }
            }
            this.parentcompaniesList = tempdata;
          }
          else if(this.roles == "User" && !checkLiteTool){
             //Role is user & not have unit360 tool
            this.parentcompaniesList = response.data.filter(
              (c) => this.$store.getters.oemCRIds.includes(c.id)
            );
          }
          if(this.roles == 'Manager' && checkLiteTool){
              //Role is manager & have unit360 tool
              // displaying all customers related to selected contractor ... based on ids provided in customerIds in login response
             this.parentcompaniesList = response.data.filter(
              (c) => this.$store.getters.customerIds.includes(c.id)
            );
            // this.parentcompaniesList = response.data;
          }
          else if(this.roles == 'Manager' && !checkLiteTool){
            //Role is manager & not have unit360 tool
              this.parentcompaniesList = response.data;
          }
           let isReportIdIdAvailable = false;
          if (this.reportId) {
            isReportIdIdAvailable = true;
          }
          //set first item in dropdown in case if user doesnot have default customer
          if (
            (this.$store.getters.referenceId == "" ||
              this.$store.getters.referenceId == null ||
              this.$store.getters.referenceId == undefined) &&
            (this.$store.getters.reportId == "" ||
              this.$store.getters.reportId == null ||
              this.$store.getters.reportId == undefined)
          ) {
            this.companySelected.id = this.parentcompaniesList[0].id;
            this.companySelected.name = this.parentcompaniesList[0].name;

            this.$store.dispatch("setReportId", this.companySelected.id);
            this.$store.dispatch("setCompanyName", this.companySelected.name);
             if (!isReportIdIdAvailable) {
            EventBus.$emit("call-dashboard");
            if (this.currentRoute == "/audit-tool") {
                EventBus.$emit("call-audit-tool");
              }
          }
            //enable checkbox
            this.disabled = false;
            this.defaultCustomer = false;
          }
            // this.getAppsheetId();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        //this.$toast.error("Unable to load the data" + error);
      }
    },
    updateOem() {
      //Update oem id to Store
      this.$store.dispatch("setoemCompanyId", this.selectedOem);
      let oem = this.oemsList.filter(
                (c) => c.id == this.selectedOem
              );
      this.selectedOemName = oem ? oem[0] ? oem[0].name : "" : "";
      this.$router.go();
    },
    updateCompany() {
      //Update CRID to Store
      this.$store.dispatch("setReportId", this.companySelected.id);
      this.$store.dispatch("setCompanyName", this.companySelected.name);
      //  this.$store.dispatch("setappsheetId", this.appSheetIdforCompany);
      // this.getAppsheetId();
      if (
        this.$router.currentRoute.path == "/" ||
        this.$router.currentRoute.path ==
          "/customer-service-list-upload" ||
        this.$router.currentRoute.path ==
          "/vendor_service_list_upload" ||
        // this.$router.currentRoute.path == "/supporttools" ||
        this.$router.currentRoute.path == "/group-assignment-tool" ||
        this.$router.currentRoute.path == "/hours-update-tool" ||
        this.$router.currentRoute.path == "/metadata-update-tool" ||
         this.$router.currentRoute.path == "/audit-tool"
      ) {
        this.$router.go();
      }
    },
    async setDefaultCustomer() {
      if (this.companySelected.id) {
        let payload = {
        userId: this.$store.getters.userId,
        customerId: this.companySelected.id,
        };
        try {
          let apiResult = await userService.setdefaultCustomer(payload);
          if (apiResult) {
            this.$store.dispatch("ReferenceIdValue", this.companySelected.id);
            this.disabled = true;
            this.$toast.success(apiResult.systemMessage);
          }
        } catch (err) {
          this.$toast.error("Unable to save as default customer");
          this.defaultCustomer = false;
        }
      } else {
        this.$toast.error("Unable to set default customer, as customer is not selected.");
        this.defaultCustomer = false;
      }
    },
    async getAppsheetId(){
          try {
        let response = await companyapi.getCompanyById(this.reportId);
        if (response) {
          this.comapnyBasedOnId = response.data;
          this.appSheetIdforCompany = this.comapnyBasedOnId.appSheetAccount.id;
          this.$store.dispatch("setappsheetId", this.appSheetIdforCompany);
        }
      } catch (error) {
        //this.$toast.error("Unable to load the data" + error);
      }
    }
  },
};
</script>
