import axios from "../api/axios";

export default {
  async execute(method, resource, data) {
    return axios.userEndPoint({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data;
    });
  },
//   getUsers() {
//     return this.execute("get", "/users");
//   },
//   createUser(payload) {
//     return this.execute("post", "/users", payload);
//   },
//   updateUser(payload, userId) {
//     return this.execute("put", `/users/${userId}`, payload);
//   },
//   getUser(userId) {
//     return this.execute("get", `/Users/${userId}`);
//   },
  setdefaultCustomer(payload) {
    return this.execute("put", `/users/defaultCustomer`, payload);
  },
  getdefaultCustomer(userId) {
    return this.execute("get", `/users/DefaultCustomer/${userId}`);
  },
  getProfile(userId) {
    return this.execute("get", `/Profile/${userId}`);
  },
  updateProfileImage(userId, payload) {
    return this.execute("put", `/Profile/${userId}/ProfileImage`, payload);
  },
  updateProfile(userId, payload) {
    return this.execute("put", `/Profile/${userId}`, payload);
  }
};
