<template>
  <!-- <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>John Leider</v-list-item-title>

        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="miniValue"
    permanent
    fixed
    dark
    width="300"
    app
  >
    <v-app-bar :dense="false" dark class="logo-nav" @click.stop.prevent="">
      <v-toolbar-title class="text-center">
        <v-avatar tile class="company-logo-mini" v-if="miniValue">
          <v-row no-gutters>
            <v-col cols="12" class="column">
              <img
                src="../assets/trackunit-logo-white.svg"
                alt="trackunit-logo"
                @click="navigateHome"
              />
            </v-col>
            <v-col cols="12" class="column mt-1">
              <div class="pro-serv-heading font-12">Activation</div>
            </v-col>
          </v-row>
        </v-avatar>
        <v-avatar tile class="company-logo" v-else>
          <v-row no-gutters>
            <v-col cols="12" class="column mt-6">
              <img
                src="../assets/logo-trackunit.png"
                alt="trackunit-logo"
                @click="navigateHome"
              />
            </v-col>
            <v-col cols="12" class="column">
              <div class="pro-serv-heading">Activation Portal</div>
            </v-col>
          </v-row>
        </v-avatar>
      </v-toolbar-title>
    </v-app-bar>
    <v-list dense style="margin-top: 40px" class="left-nav-list">
      <div v-for="item in items" :key="item.title">
        <v-list-item
          :to="resolvePath(item.path)"
          ripple="ripple"
          @click.stop.prevent=""
        >
          <v-list-item-icon>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon dark v-bind="attrs" v-on="on" class="navigation-icon">
                  {{ item.icon }}
                </v-icon>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>

      <!-- <v-list-item>
            <v-list-item-content>
            <v-list-item-title v-if="!mini">Tiles</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
    </v-list>
    <!-- <the-layout-drawer-list :dense="navbarDense" :routes="routesPath" icon-show /> -->
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
@import "../assets/styles/variables.scss";
.v-list-item__title {
  font-family: "Segoe UI", "Roboto", sans-serif;
  font-weight: 400 !important;
  font-size: 16px !important;
}
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 16px;
}
.left-nav-list .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  margin-left: 4px;
  height: 52px;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  color: $neutral-white !important;
  font-weight: 500;
}
.left-nav-list .v-list-item__title {
  font-weight: 500 !important;
}

a.v-list-item--active.v-list-item.v-list-item--link.theme--dark {
  height: 52px;
  padding: 16px 32px;
  display: flex;
  align-items: center;
}
.v-application--is-ltr .v-list-item__icon:first-child {
  margin: 0;
  margin-right: 16px;
}
.v-list--dense .v-list-item .v-list-item__content {
  padding: 0;
  padding-top: 5px;
}
.arrow-button {
  top: 55px;
  width: 30px;
  background: $black-shade-3;
  transition: left 0.1s ease-in-out 0s, visibility 0.1s ease-in-out 0s;
  position: absolute;
  box-shadow: rgb(56 58 60) 0px 0px 0px 1px !important;
  z-index: 1;
}
.navigation-icon {
  font-size: 30px !important;
  color: $neutral-white !important;
}
.arrow-button-right {
  left: 73px;
}

.arrow-button-left {
  left: 280px;
}
.company-logo-mini {
  width: 48px !important;
  height: 48px !important;
}
.company-logo-mini img {
  width: 29.19px;
  height: 32px;
  cursor: pointer;
}
.company-logo img {
  height: 32px;
  width: auto;
  cursor: pointer;
}

.v-toolbar__content {
  text-align: center;
}
.v-toolbar__title {
  width: 100%;
}
.company-logo .column {
  width: 100% !important;
}
.pro-serv-heading {
  font-size: 14px;
  color: $trackunit-red;
  font-weight: 500;
  margin-top: -10px;
  margin-left: 50px !important;
}
.font-12 {
  font-size: 9px !important;
  margin-left: 8px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import { resolve } from "path";
import navigation from "../config/navigation.json";
// import apiService from "../api/api.service.js";
// import TheLayoutDrawerList from "./TheLayoutDrawerList.vue";
// import {adminRoutes} from "../../config/admin.route.config"
//import  { constantRoutes } from '@/router';

const hasPermission = (role, route) => {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(role);
  }
  return true;
};

const filterAsyncRoutes = (roles, routes) => {
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(roles, tmp.children);
      }
      res.push(tmp);
    }
  });

  return res;
};
export default {
  name: "TheLayoutDrawer",
  props: {
    basePath: {
      type: String,
      default: "",
    },
    miniValue: { type: Boolean, default: true },
  },
  components: {
    // TheLayoutDrawerList,
  },
  data: () => ({
    builds: [],
    toolRoutes: [
      {
        title: "Activation Services",
        icon: "mdi-table-cog",
        name: "Activation Services",
        path: "/activation-services",
        toolId: "BE73109C-E30D-4FC0-B4AA-587AA6C5EF4E",
        meta: {
          roles: [
            "OEM",
            "Manager", 
            "User"
          ],
        },
      },
      {
        title: "Grop Assignment Tool",
        icon: "mdi-group",
        name: "Grop Assignment Tool",
        path: "/group-assignment-tool",
        toolId: "775062DD-EF70-4EE8-B079-5B0D95EB9B13",
        meta: {
          roles: ["Customer", "Contractor", "OEM", "Manager", "User"],
        },
      },
      {
        title: "Hours Update Tool",
        icon: "mdi-timer",
        name: "Hours Update Tool",
        path: "/hours-update-tool",
        toolId: "52C01730-7DF1-40BF-86CC-14345AE66513",
        meta: {
          roles: ["Customer", "Contractor", "OEM", "Manager", "User"],
        },
      },
      {
        title: "MetaData Update Tool",
        icon: "mdi-timetable",
        name: "MetaData Update Tool",
        path: "/metadata-update-tool",
        toolId: "3C134EE5-BA6B-45C7-92F0-A74AB48875AF",
        meta: {
          roles: ["Customer", "Contractor", "OEM", "Manager", "User"],
        },
      },
       {
        title: "Service List Upload",
        icon: "mdi-calendar-clock",
        name: "Service List Upload",
        path: "/customer-service-list-upload",
        toolId: "A89B565B-8C01-407C-A2C0-80CAE430077A",
        meta: {
          roles: ["Customer", "Contractor", "OEM", "Manager", "User"],
        },
      },
      {
        title: "Service List Upload",
        icon: "mdi-calendar-clock",
        name: "Service List Upload",
        path: "/vendor_service_list_upload",
        toolId: "372AB85A-49D2-4093-9E24-3434351B90FD",
        meta: {
          roles: ["Customer", "Contractor", "OEM", "Manager", "User"],
        },
      },
      {
        title: "360 Lite",
        icon: "mdi-checkbox-marked-outline",
        name: "360 Lite",
        path: "/360-lite",
        toolId: "B4ADFA53-83C3-46FF-A158-F1CBDBB0E97A",
        meta: {
          roles: ["Customer", "Contractor", "OEM", "Manager", "User"],
        },
      },
    ],
    routesPath: [],
    color: "dark",
    drawer: true,
    items: [],
    mini: true,
    parentPath: null,
  }),
  computed: {
    ...mapGetters([
      // "navbarDense",
      // "navbarShow",
      // "navbarLogo",
      // "toolbarDense",
      "userId",
      "roles",
    ]),
  },
  created() {
    this.parentPath = this.$route.matched[0].name;
    this.items = filterAsyncRoutes(this.roles, navigation);
    this.getToolsList();
    // switch(this.roles) {
    //   case 'Admin':
    //     this.routesPath = adminRoutes;
    //     break;
    //   case 'Install Manager':
    //     this.routesPath = adminRoutes;
    //     break;
    //   case 'Sales':
    //     this.routesPath = installAdminRoutes
    // }
  },
  mounted() {},
  methods: {
    // stateNavbarShow(state) {
    //   this.$store.dispatch("NavbarState", { state });
    // },
    changeRoute(item) {
      if (this.$route.name != item.path) {
        this.$router.push({ name: item.path });
      }
    },
    isExternal(path) {
      return /^(https?:|mailto:|tel:)/.test(path);
    },
    resolvePath(path) {
      if (this.isExternal(path)) {
        return path;
      }
      return resolve(this.basePath, path);
    },
    navigateHome() {
      this.$router.push("/");
    },
    getToolsList() {
      try {
        // let response = await apiService.getTools(this.userId);
        let response = this.$store.getters.toolIds;
        if (response.length>0) {
          let tempToolsList = response;
          var newToolsList = [];
          for (var k = 0; k < tempToolsList.length; k++) {
            newToolsList.push({
              index: k,
              toolId: tempToolsList[k],
            });
          }
          if (newToolsList && newToolsList.length > 0) {
            this.builds = this.toolRoutes;
          }
          //Filter Tools from Response
          let finalToolsList = [];

          for (var m = 0; m < this.toolRoutes.length; m++) {
            for (var x = 0; x < newToolsList.length; x++) {
              if (this.toolRoutes[m].toolId == newToolsList[x].toolId) {
                finalToolsList.push(this.toolRoutes[m]);
              }
            }
          }
          this.builds = finalToolsList;
          this.items.push(...this.builds);
        } else {
          this.systemMessage = "Tools for this Company is unavailable";
          // this.isBuildData = false;
        }
      } catch (err) {
        // this.isBuildData = false;
        console.log("error", err);
      }
    },
  },
  watch: {
    miniValue() {
      this.$emit("mini", this.miniValue);
    },
  },
};
</script>
