<template>
  <v-app>
    <!-- <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar> -->
    <v-btn
      icon
      @click.stop="isNavigationExpanded = !isNavigationExpanded"
      class="arrow-button"
      :class="isNavigationExpanded ? 'arrow-button-right' : 'arrow-button-left'"
      v-if="isUserLoggedin"
    >
      <v-icon v-if="!isNavigationExpanded">mdi-chevron-left</v-icon>
      <v-icon v-else>mdi-chevron-right</v-icon>
    </v-btn>
    <TheLayoutDrawer
      v-if="isUserLoggedin"
      @mini="onNavigationExpand"
      :miniValue="isNavigationExpanded"
    />
    <TheLayoutToolbar
      v-if="isUserLoggedin"
      :class="
        isUserLoggedin
          ? isNavigationExpanded
            ? 'secured-mini-header'
            : 'secured-expanded-header'
          : ''
      "
    />
    <v-main
      :class="
        isUserLoggedin
          ? isNavigationExpanded
            ? 'v-main-secured-mini'
            : 'v-main-secured-expanded'
          : ''
      "
    >
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import TheLayoutDrawer from "../src/views/TheLayoutDrawer.vue";
import TheLayoutToolbar from "../src/views/TheLayoutToolbar.vue";
// import tokenAuthService from "../src/api/tokenAuthenticationService";
import store from "../src/store";
// import config from "../src/config";
// import router from "../src/router";
export default {
  name: "App",
  components: {
    TheLayoutDrawer,
    TheLayoutToolbar
  },
  data() {
    return {
      isUserLoggedin: false,
      isNavigationExpanded: true,
      counter: 0
    };
  },
  methods: {
    // setAuthTimers() {
    //   this.refreshInterval = setInterval(() => {
    //     //console.log(config.authTokenInterval);
    //     this.callTokenUpdate();
    //   }, parseInt(config.AUTH_TOKEN_INTERVAL));
    // },
    // callTokenUpdate() {
    //   var refershObject = {
    //     grant_type: "refresh_token",
    //     refresh_token: store.getters.refreshToken,
    //     username: store.getters.user
    //   };
    //   tokenAuthService.getAccessTokenByRefreshToken(
    //     refershObject,
    //     response => {
    //       store.commit("SET_TOKEN", response.access_token);
    //       store.commit("SET_REFRESH_TOKEN", response.refresh_token);
    //       store.commit("SET_TOKEN_EXPIRY_TIME", response.expires_at);
    //       // this.$store.commit("updateisTokenRefreshing", false);
    //     },
    //     errorResponse => {
    //       console.log(errorResponse);
    //       // this.$store.commit("updateisTokenRefreshing", false);
    //       this.$toast.error("Session expired, please kindly login again");
    //       store.dispatch("LogOut");
    //       router.push("/signin");
    //     }
    //   );
    // },
    onNavigationExpand(mini) {
      this.isNavigationExpanded = mini;
    }
  },
  watch: {
    // isUserLoggedin(isLogged) {
    //   if (isLogged) {
    //     this.setAuthTimers();
    //   }
    // },
    isNavigationExpanded() {},
    $route() {
      //scroll to top of the page
      window.scrollTo(0, 0);
      this.isUserLoggedin = store.getters.token;
      // //Resetting manually as app.vue just get remounted even on logout
      // if (!this.isUserLoggedin) {
      //   clearInterval(this.refreshInterval);
      //   return;
      // }
    }
  }
};
</script>
<style scoped lang="scss">
@import "./assets/styles/variables.scss";

.v-main-secured-mini {
  padding: 64px 0px 0px 90px !important;
}
.v-main-secured-expanded {
  padding: 64px 0px 0px 300px !important;
}

.arrow-button {
  top: 55px;
  width: 30px !important;
  height: 30px !important;
  background: $black-shade-3;
  transition: left 0.1s ease-in-out 0s, visibility 0.1s ease-in-out 0s;
  position: fixed;
  box-shadow: rgb(56 58 60) 0px 0px 0px 1px !important;
  z-index: 10;
}

.arrow-button:hover {
  box-shadow: rgb(214 218 220) 0px 0px 0px 1px !important;
}

.arrow-button-right {
  left: 73px;
}

.arrow-button-left {
  left: 280px;
}
.mdi-chevron-right,
.mdi-chevron-left {
  font-size: 30px !important;
  color: $neutral-white !important;
}
.secured-mini-header {
  padding-left: 34px !important;
  background-color: $neutral-white !important;
}
.secured-expanded-header {
  padding-left: 0;
  background-color: $neutral-white !important;
}
</style>
