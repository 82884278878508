import Vue from "vue";
import Vuex from "vuex";
import login from "@/api/login";
import VuexPersistence from "vuex-persist";
import Crypto from "crypto-js";
import Cookie from "js-cookie";
import { v1 as uuidv4 } from "uuid";
import appsheettoolsService from "././../api/appsheettools.service";
import apiService from "././../api/api.service";

const cookieName = "1P_JAR";
const storageKey = "vuex";
const encryptionToken = Cookie.get(cookieName) || uuidv4();
Vue.use(Vuex);

Cookie.set(cookieName, encryptionToken);
const vuexLocal = new VuexPersistence({
  storage: {
    getItem: () => {
      const store = window.localStorage.getItem(storageKey);

      if (store) {
        try {
          const bytes = Crypto.AES.decrypt(store, encryptionToken);
          return JSON.parse(bytes.toString(Crypto.enc.Utf8));
        } catch (e) {
          window.localStorage.removeItem(storageKey);
        }
      }

      return null;
    },
    setItem: (key, value) => {
      const store = Crypto.AES.encrypt(value, encryptionToken).toString();
      return window.localStorage.setItem(storageKey, store);
    },
    removeItem: () => window.localStorage.removeItem(storageKey)
  }
});

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    token: "",
    refreshToken: "",
    tokenExpiryTime: "",
    roles: "",
    user: "",
    name: "",
    userId: "",
    customerId: "",
    customerIds: "",
    oemCRIds:"",
    toolIds: "",
    referenceId: "",
    reportvalue: "",
    auditFinanceCustomer: "",
    auditFinanceFromDate: "",
    auditFinanceToDate: "",
    customerToken: "",
    customerReferenceId: "",
    companyName: "",
    reportId: "",
    loggedInReferenceId: "",
    setErrorMessageLogin: "",
    oemCompanyId: "",
    oemCompanyIdsList: "",
    appsheetId:"",
    vendorIds:"",
    vendorName: "",
  },
  getters: {
    token: state => state.token,
    refreshToken: state => state.refreshToken,
    tokenExpiryTime: state => state.tokenExpiryTime,
    roles: state => state.roles,
    name: state => state.name,
    user: state => state.user,
    userId: state => state.userId,
    customerId: state => state.customerId,
    customerIds: state => state.customerIds,
    toolIds: state => state.toolIds,
    referenceId: state => state.referenceId,
    avatar: () => null,
    reportvalue: state => state.reportvalue,
    auditFinanceCustomer: state => state.auditFinanceCustomer,
    auditFinanceFromDate: state => state.auditFinanceFromDate,
    auditFinanceToDate: state => state.auditFinanceToDate,
    customerToken: state => state.customerToken,
    customerReferenceId: state => state.customerReferenceId,
    companyName: state => state.companyName,
    reportId: state => state.reportId,
    loggedInReferenceId: state => state.loggedInReferenceId,
    setErrorMessageLogin: state => state.setErrorMessageLogin,
    oemCompanyId: state => state.oemCompanyId,
    oemCompanyIdsList: state => state.oemCompanyIdsList,
    appsheetId: state => state.appsheetId,
    vendorIds: state => state.vendorIds,
    vendorName: (state) => state.vendorName,
    oemCRIds: (state) => state.oemCRIds,
  },
  mutations: {
    SET_USER_INFO: (state, payload) => {
      if (payload.logout) {
        state.token = "";
        state.refreshToken = "";
        state.tokenExpiryTime = "";
        state.roles = "";
        state.user = "";
        state.name = "";
        state.userId = "";
        state.customerId = "";
        state.customerIds = "";
        state.toolIds = "";
        state.referenceId = "";
        state.reportvalue = "";
        state.auditFinanceCustomer = "";
        state.auditFinanceFromDate = "";
        state.auditFinanceToDate = "";
        state.customerToken = "";
        state.customerReferenceId = "";
        state.companyName = "";
        state.reportId = "";
        state.loggedInReferenceId = "";
        state.setErrorMessageLogin = "";
        state.oemCompanyId = "";
        state.oemCompanyIdsList = "";
        state.appsheetId = "";
        state.vendorIds = "";
        state.vendorName = "";
        state.oemCRIds = "";
      } else {
        state.token = payload.token || state.token;
        // state.refreshToken = payload.refreshToken || state.refreshToken;
        // state.tokenExpiryTime = payload.tokenExpiryTime || state.tokenExpiryTime;
        state.roles = payload.userRole || state.userRole;
        state.user = payload.username || state.username;
        state.name = payload.flName || state.flName;
        state.userId = payload.userId || state.userId;
        state.customerId = payload.customerId || state.customerId;
        state.customerIds = payload.customerIds || state.customerIds;
        state.toolIds = payload.toolIds || state.toolIds;
        state.referenceId = payload.referenceId || state.referenceId;
        state.reportvalue = payload.reportvalue || state.reportvalue;
        state.auditFinanceCustomer =
          payload.auditFinanceCustomer || state.auditFinanceCustomer;
        state.auditFinanceFromDate =
          payload.auditFinanceFromDate || state.auditFinanceFromDate;
        state.auditFinanceToDate =
          payload.auditFinanceToDate || state.auditFinanceToDate;
        state.customerToken = payload.customerToken || state.customerToken;
        state.customerReferenceId =
          payload.customerReferenceId || state.customerReferenceId;
        state.companyName = payload.companyName || state.companyName;
        state.reportId = payload.reportId || state.reportId;
        state.loggedInReferenceId =
          payload.loggedInReferenceId || state.loggedInReferenceId;
        state.setErrorMessageLogin = payload.setErrorMessageLogin || state.setErrorMessageLogin;
        state.oemCompanyId = payload.oemCompanyId || state.oemCompanyId;
        state.oemCompanyIdsList = payload.oemCompanyIdsList || state.oemCompanyIdsList;
        state.appsheetId = payload.appsheetId || state.appsheetId;
        state.vendorIds = payload.vendorIds || state.vendorIds;
        state.vendorName = payload.vendorName || state.vendorName;
        state.oemCRIds = payload.oemCRIds || state.oemCRIds;
      }
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken;
    },
    SET_TOKEN_EXPIRY_TIME: (state, tokenExpiryTime) => {
      state.tokenExpiryTime = tokenExpiryTime;
    },
    REPORT_VALUE: (state, value) => {
      state.reportvalue = value;
    },
    REFERENCE_ID: (state, value) => {
      state.referenceId = value;
    },
    CUSTOMER_TOKEN: (state, value) => {
      state.customerToken = value;
    },
    SET_Customer: (state, value) => {
      state.auditFinanceCustomer = value;
    },
    SET_FromDate: (state, value) => {
      state.auditFinanceFromDate = value;
    },
    SET_ToDate: (state, value) => {
      state.auditFinanceToDate = value;
    },
    CUSTOMER_REFERENCEID: (state, value) => {
      state.customerReferenceId = value;
    },
    SET_COMPANYNAME: (state, value) => {
      state.companyName = value;
    },
    SET_REPORTID: (state, value) => {
      state.reportId = value;
    },
    // loggedInReferenceId
    SET_LOGGEDINREFERENCEID: (state, value) => {
      state.loggedInReferenceId = value;
    },
    SET_ERROR_MESSAGE_LOGIN:(state, value) => {
      state.setErrorMessageLogin = value;
    },
    SET_OEMCOMPANYID: (state, value) => {
      state.oemCompanyId = value;
    },
    SET_OEMCOMPANYIDSLIST: (state, value) => {
      state.oemCompanyIdsList = value;
    },
    SET_APPSHEETID: (state, value) => {
      state.appsheetId = value;
    },
    SET_VENDOR_ID:(state, value) => {
      state.vendorIds = value;
    },
    SET_VENDOR_NAME: (state, value) => {
      state.vendorName = value;
    },
    SET_CUSTOMER_IDS: (state, value) => {
      state.customerIds = value;
    },
    SET_OEM_CUSTOMER_IDS: (state, value) => {
      state.oemCRIds = value;
    }
  },
  actions: {
    LoginByEmail: async ({ commit }, payload) => {
      try {
        const response = await login.loginByEmail(
          payload.username.trim(),
          payload.password
        );
        if (JSON.stringify(response.userInformation) !== "{}") {
          response.userInformation.token = "1234abcd";
          response.userInformation.flName =
            response.userInformation.firstName +
            " " +
            response.userInformation.lastName;
          await commit("SET_TOKEN", response.userInformation.token);
          await commit("SET_USER_INFO", response.userInformation);
          // await commit("CUSTOMER_TOKEN", response.userInformation.apiToken);
          ////set default customer values to store
          // await commit("SET_REPORTID", response.defaultCompanyId);
          // await commit("SET_COMPANYNAME", response.defaultCompanyName);
          await commit(
            "SET_LOGGEDINREFERENCEID",
            response.userInformation.referenceId
          );
          await commit("REFERENCE_ID", response.defaultCompanyId);

          await commit(
            "SET_OEMCOMPANYID",
            response.userInformation.oemCompanyId
          );
          await commit("SET_VENDOR_ID", response.userInformation.vendorId);

          if (
            response.userInformation.userRole != "Customer" &&
            response.userInformation.userRole != "Contractor" &&
            response.userInformation.userRole != "OEM"
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          this.$toast.error("Please enter valid credentials.");
        }
      } catch (err) {
        console.warn("[vuex.user] LoginByEmail", err);
      }
    },
    UserInfo: async ({ commit }, payload) => {
      try {
        const response = await apiService.getUser(
          payload.userId
        );
        if (response.isSuccess) {
          if (response.data && JSON.stringify(response.data) !== "{}") {
            // response.userInformation.access_token = uuidv4();
            response.data.flName =
              response.data.firstName +
              " " +
              response.data.lastName;
            response.data.username = response.data.email;
            response.data.userId = response.data.id;
            for (var a=0; a<response.data.userMappings.length; a++) {
              if (response.data.userMappings[a].role["name"].split("-")[0] == 'Activation') {
                
                response.data["userRole"] = response.data.userMappings[a].role["name"].split("-")[1];
                // only show this selected customers in all customers dropdown?
                response.data["customerIds"] = response.data.userMappings[a]["customerIds"] ? response.data.userMappings[a]["customerIds"].split(",") : "";
                response.data["oemCrIds"] = response.data.userMappings[a]["oemCrIds"] ? response.data.userMappings[a]["oemCrIds"].split(",") : "";
                response.data["referenceId"] = response.data.userMappings[a]["customerIds"];
                response.data["vendorId"] = response.data.userMappings[a]["contractorIds"];
                // only show this selected tools in all customers dropdown?
                response.data["toolIds"] = response.data.userMappings[a]["toolIds"] ? response.data.userMappings[a]["toolIds"].split(",") : "";
              } 
            }
            await commit("SET_USER_INFO", response.data);
            await commit(
              "SET_LOGGEDINREFERENCEID",
              response.data.referenceId
            );
            ////set default customer values to store
            let referenceIdsList = response.data.referenceId ? response.data.referenceId.split(",") : [];
            if (response.data.defaultCustomerId && response.data.defaultCustomerName && (referenceIdsList.length > 0 || response.data["userRole"] == "Manager") && (referenceIdsList.includes(response.data.defaultCustomerId) || response.data["userRole"] == "Manager")) {
              // await commit("SET_REPORTID", response.data.defaultCustomerId);
              // await commit("SET_CR_ID", response.data.defaultCustomerId);
              // await commit("SET_COMPANYNAME", response.data.defaultCustomerName);
            } else {
              await commit("SET_REPORTID", "");
              // await commit("SET_CR_ID", "");
              await commit("SET_COMPANYNAME", "");
            }
            await commit("REFERENCE_ID", response.data.defaultCustomerId);
            await commit(
              "SET_SUPPORT_REF_ID",
              response.data.supportReferenceId
            );
            await commit("SET_VENDOR_NAME", response.data.vendorId);
            await commit("SET_CUSTOMER_IDS", response.data.customerIds);
            await commit("SET_OEM_CUSTOMER_IDS", response.data.oemCrIds);
            
            // await commit("SET_REPORTID", null);
            // await commit("SET_COMPANYNAME", null);
            // await commit("REFERENCE_ID", null);
            if (response.data.userRole != "User" && response.data.userRole != "Manager") {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } catch (err) {
        console.warn("[vuex.user] UserInfo", err);
        return false;
      }
    },
    // Login user dispatch
    getAuthToken: async ({ commit }, payload) => {
      try {
        const response = await login.getAuthToken(
          payload.token
        );
        if (response.isSuccess) {
          
          if (response.data) {
            await commit("SET_TOKEN", response.data.token.access_token);
            await commit(
              "SET_REFRESH_TOKEN",
              response.data.token.refresh_token
            );
            await commit(
              "SET_TOKEN_EXPIRY_TIME",
              response.data.token.expires_at
            );
            if (response.data.user && JSON.stringify(response.data.user) !== "{}") {
              // response.userInformation.access_token = uuidv4();
              response.data.user.flName =
              response.data.user.firstName +
                " " +
                response.data.user.lastName;
                response.data.user.username = response.data.user.email;
                response.data.user.userId = response.data.user.id;
              for (var a=0; a<response.data.user.userMappings.length; a++) {
                if (response.data.user.userMappings[a].role["name"].split("-")[0] == 'Activation') {
                  response.data.user["userRole"] = response.data.user.userMappings[a].role["name"].split("-")[1];
                  // only show this selected customers in all customers dropdown?
             
                  response.data.user["customerIds"] = response.data.user.userMappings[a]["customerIds"] ? response.data.user.userMappings[a]["customerIds"].split(",") : "";
                  response.data.user["oemCrIds"] = response.data.user.userMappings[a]["oemCrIds"] ? response.data.user.userMappings[a]["oemCrIds"].split(",") : "";
                  response.data.user["referenceId"] = response.data.user.userMappings[a]["customerIds"];
                  response.data.user["vendorId"] = response.data.user.userMappings[a]["contractorIds"];
                  response.data.user["oemCompanyIdsList"] = response.data.user.userMappings[a]["oemIds"] ? response.data.user.userMappings[a]["oemIds"].split(",") : "";
                  response.data.user["oemCompanyId"] = response.data.user.userMappings[a]["oemIds"] ? response.data.user.userMappings[a]["oemIds"].split(",")[0] : "";
                  // only show this selected tools in all customers dropdown?
                  response.data.user["toolIds"] = response.data.user.userMappings[a]["toolIds"] ? response.data.user.userMappings[a]["toolIds"].split(",") : "";
                } 
              }
              await commit("SET_USER_INFO", response.data.user);
  
              ////set default customer values to store
              await commit("SET_REPORTID", response.data.user.defaultCustomerId);
              await commit("SET_COMPANYNAME", response.data.user.defaultCustomerName);
              await commit(
                "SET_LOGGEDINREFERENCEID",
                response.data.user.referenceId
              );
              await commit("REFERENCE_ID", response.data.user.defaultCustomerId);
              await commit(
                "SET_OEMCOMPANYID",
                response.data.user.oemCompanyId
              );
              await commit(
                "SET_OEMCOMPANYIDSLIST",
                response.data.user.oemCompanyIdsList
              );
              // await commit("SET_VENDOR_ID", response.data.user.vendorId);
              await commit("SET_VENDOR_NAME", response.data.user.vendorId);
              await commit("SET_CUSTOMER_IDS", response.data.user.customerIds);
              await commit("SET_OEM_CUSTOMER_IDS", response.data.user.oemCrIds);
              // await commit("SET_REPORTID", null);
              // await commit("SET_COMPANYNAME", null);
              // await commit("REFERENCE_ID", null);
  
              if (response.data.user.userRole != "User" && response.data.user.userRole != "Manager") {
                await commit("SET_ERROR_MESSAGE_LOGIN", response.systemMessage);
                return false;
              } else {
                await commit("SET_ERROR_MESSAGE_LOGIN", "");
                return true;
              }
            } else {
              await commit("SET_ERROR_MESSAGE_LOGIN", response.systemMessage);
              return false;
            }
            // let token = response.tokenResponse.access_token;
            // let tokenResponse = jwt_decode(token);
            // console.log(tokenResponse["userid"])
            // dispatch('UserInfo', tokenResponse["userid"])
            // .then(() => {
            //   console.log("123")
            // })
            // .catch(
            //   console.log("error")
            // );
            // if (JSON.stringify(response.userInformation) !== "{}") {
            //   // response.userInformation.access_token = uuidv4();
            //   response.userInformation.flName =
            //     response.userInformation.firstName +
            //     " " +
            //     response.userInformation.lastName;
            //   await commit("SET_USER_INFO", response.userInformation);

            //   ////set default customer values to store
            //   await commit("SET_REPORTID", response.defaultCompanyId);
            //   await commit("SET_COMPANYNAME", response.defaultCompanyName);
            //   await commit("REFERENCE_ID", response.defaultCompanyId);
            //   await commit("SET_SUPPORT_REF_ID", response.userInformation.supportReferenceId);
            //   // await commit("SET_REPORTID", null);
            //   // await commit("SET_COMPANYNAME", null);
            //   // await commit("REFERENCE_ID", null);
            //   return true;
            // } else {
            //   return false;
            // }
            // return true;
          } else {
            await commit("SET_ERROR_MESSAGE_LOGIN", response.systemMessage);
              return false;
          }
        } else {
          await commit("SET_ERROR_MESSAGE_LOGIN", response.systemMessage);
            return false;
        }
      } catch (err) {
        console.warn("[vuex.user] getAuthToken", err);
      }
    },
    GetCustomerInfo: async ({ commit }, payload) => {
      try {
        const response = await appsheettoolsService.getContractorCustomers(
          payload.contractorId
        );
        if (response.isSuccess) {
          if (response.data) {
            await commit(
              "SET_LOGGEDINREFERENCEID",
              response.data.join(",")
            );
            await commit("SET_CUSTOMER_IDS", response.data);
            ////set default customer values to store
            let referenceIdsList = response.data;
            if (payload.store.getters.reportId && payload.store.getters.companyName && referenceIdsList.length > 0 && referenceIdsList.includes(payload.store.getters.reportId)) {
              await commit("SET_REPORTID", payload.store.getters.reportId);
              // await commit("SET_CR_ID", payload.store.getters.CRId);
              await commit("SET_COMPANYNAME", payload.store.getters.companyName);
            } else {
              await commit("SET_REPORTID", "");
              // await commit("SET_CR_ID", "");
              await commit("SET_COMPANYNAME", "");
            }
            await commit("REFERENCE_ID", payload.store.getters.reportId);
            await commit("SET_ERROR_MESSAGE_LOGIN", "");
            return true;
          } else {
            await commit("SET_ERROR_MESSAGE_LOGIN", "Unable to fetch user profile details, Please try again");
            return false;
          }
        } else {
          await commit("SET_ERROR_MESSAGE_LOGIN", "Unable to fetch user profile details, Please try again");
          return false;
        }
      } catch (err) {
        console.warn("[vuex.user] GetCustomerInfo", err);
        return false;
      }
    },
    Reportvalue: async (context, value) => {
      context.commit("REPORT_VALUE", value);
    },
    ReferenceIdValue: async ({ commit }, value) => {
      commit("REFERENCE_ID", value);
    },
    setcustomerToken: async ({ commit }, value) => {
      commit("CUSTOMER_TOKEN", value);
    },
    setCustomer: async (context, value) => {
      context.commit("SET_Customer", value);
    },
    setFromDate: async (context, value) => {
      context.commit("SET_FromDate", value);
    },
    setToDate: async (context, value) => {
      context.commit("SET_ToDate", value);
    },
    setCustomerReferenceId: async (context, value) => {
      context.commit("CUSTOMER_REFERENCEID", value);
    },
    setCompanyName: async (context, value) => {
      context.commit("SET_COMPANYNAME", value);
    },
    setReportId: async (context, value) => {
      context.commit("SET_REPORTID", value);
    },
    setLoggedInReferenceId: async (context, value) => {
      context.commit("SET_LOGGEDINREFERENCEID", value);
    },
    setErrorMessageLogin:async (context, value) => {
      context.commit("SET_ERROR_MESSAGE_LOGIN",value);
    },
    setoemCompanyId: async (context, value) => {
      context.commit("SET_OEMCOMPANYID", value);
    },
    setoemCompanyIdsList: async (context, value) => {
      context.commit("SET_OEMCOMPANYIDSLIST", value);
    },
    setappsheetId: async (context, value) => {
      context.commit("SET_APPSHEETID", value);
    },
    setVednorId:async (context, value) => {
      context.commit("SET_VENDOR_ID",value);
    },
    GetUserInfo: async () => {
      try {
        const response = false;

        // Since mockjs does not support custom status codes, it can only be hacked like this
        if (!response) {
          throw new Error("Verification failed, please login again.");
        }

        // Verify returned roles are a non-null array
        if (response.user.roles && response.user.roles.length === 0) {
          throw new Error("getInfo: roles must be a non-null array!");
        }

        //commit('SET_USER_INFO', response.user);
      } catch (err) {
        console.warn("[vuex.user] GetUserInfo", err);
      }
    },
    LogOut: async ({ commit }) => {
      try {
        await commit("SET_USER_INFO", { logout: true });
        localStorage.clear();
      } catch (err) {
        console.warn("[vuex.user] LogOut", err);
      }
    }
  },
  modules: {}
});
