// import axios from "axios";
// import env from "../config";
import axios from "../api/axios";
// const client = axios.create({
//   baseURL: env.APPSHEET_TOOLS_ENDPOINT,
//   json: true
// });

export default {
  async execute(method, resource, data) {
    return axios
      .appSheetToolsEndPoint({
        method,
        url: resource,
        data
      })
      .then(req => {
        return req.data;
      });
  },
  getAllVendors() {
    return this.execute("get", `/MasterData/Vendors`);
  },

  getUserCustomers() {
    return this.execute("get", `/Company/UserCompanies`)
  },
  //Service List upload for Customer
  CustomerServiceList(customerId) {
    return this.execute("get", `/Customer/Logs/${customerId}`);
  },
  ServiceFileUpload(customerId, payload) {
    return this.execute("post", `/Customer/${customerId}`, payload);
  },
  GetViewServiceLogs(toolId, customerId) {
    return this.execute(
      "get",
      `/Customer/Log/${toolId}?customerId=${customerId}`
    );
  },
  ExecuteServiceTools(payload) {
    return this.execute("put", `/Customer/Execute`, payload);
  },
  DeleteServiceLogs(toolId) {
    return this.execute("delete", `/AppSheetServices/Log/${toolId}`);
  },
  //Service List upload for Customer

  //Service List Upload Vendor

  ServiceFileUploadVendor(vendorId, payload) {
    return this.execute("post", `/Vendor/${vendorId}`, payload);
  },
  VendorServiceList(vendorId) {
    return this.execute("get", `/Vendor/Logs/${vendorId}`);
  },
  GetViewServiceLogsVendor(toolId, vendorId) {
    return this.execute("get", `/Vendor/Log/${toolId}?customerId=${vendorId}`);
  },
  ExecuteServiceToolsVendor(payload) {
    return this.execute("put", `/Vendor/Execute`, payload);
  },

  //Service List Upload Vendor
  getVendorActivationServices(companyID, status, oemCRID,fromDate,toDate) {
    return this.execute(
      "get",
      `/ActivationServices/${oemCRID}?companyId=${companyID}&status=${status}&fromDate=${fromDate}&toDate=${toDate}`
    );
  },
  getVendorActivationServicesNonCommitted(companyID, status, oemCRID,fromDate,toDate) {
    return this.execute(
      "get",
      `/ActivationServices/NonCommitted/${oemCRID}?companyId=${companyID}&status=${status}&fromDate=${fromDate}&toDate=${toDate}`
    );
  },
  getVendorActivationServicesCommitted(companyID, oemCRID,fromDate,toDate) {
    return this.execute(
      "get",
      `/ActivationServices/Committed/${oemCRID}?companyId=${companyID}&fromDate=${fromDate}&toDate=${toDate}`
    );
  },
  // getVendorActivationServices(companyID,oemCRID) {
  //   return this.execute(
  //     "get",
  //     `/ActivationServices/${oemCRID}?companyId=${companyID}`
  //   );
  // },
  createVendorActivationService(oemCRID, companyID, payload) {
    return this.execute(
      "post",
      `/ActivationServices/${oemCRID}?companyId=${companyID}`,
      payload
    );
  },
  updateVendorActivationService(oemCRID, companyID, recordID, payload) {
    return this.execute(
      "put",
      `/ActivationServices/${oemCRID}/${recordID}?companyId=${companyID}`,
      payload
    );
  },
  deleteVendorActivationService(companyID, recordID) {
    return this.execute(
      "delete",
      `/ActivationServices/${recordID}?companyId=${companyID}`
    );
  },
  commitVendorActivationServices(oemCRID, companyID, payload, committedBy) {
    return this.execute(
      "post",
      `/ActivationServices/${oemCRID}/commit?companyId=${companyID}&committedBy=${committedBy}`,
      payload
    );
  },
  deleteAllNonCommittedServices(payload) {
    return this.execute(
      "delete",
      `/ActivationServices/all`,
      payload
    );
  },
  deleteNonCommittedServices(payload) {
    return this.execute(
      "delete",
      `/ActivationServices/Selected`,
      payload
    );
  },
  checkMachineIDIsValid(oemCRID, companyID, machineID, tuSerial) {
    return this.execute(
      "get",
      `/ActivationServices/${oemCRID}/Valid?companyId=${companyID}&vin=${encodeURIComponent(machineID)}&tuSerial=${encodeURIComponent(tuSerial)}`
    );
  },
  getMasterDataBrands() {
    return this.execute("get", `/MasterData/Brands`);
  },
  getMasterDataMachineTypes() {
    return this.execute("get", `/MasterData/MachineTypes`);
  },
  getMasterDataDevices() {
    return this.execute("get", `/MasterData/Devices`);
  },
  getMasterDataVendors() {
    return this.execute("get", `/MasterData/Vendors`);
  },
  getMasterDataVendorTechnicians() {
    return this.execute("get", `/MasterData/VendorTechnicians`);
  },
  getMasterDataStates() {
    return this.execute("get", `/MasterData/States`);
  },

  //Audit tool
  getAudits(
    searchAudit,
    SelectedCustomer,
    fromDate,
    toDate,
    serviceType,
    bookmark,
    contractor
  ) {
    return this.execute(
      "get",
      `/Unit360Lite?query=${searchAudit}&fromDate=${fromDate}&toDate=${toDate}&serviceType=${serviceType}&bookmark=${bookmark}&contractor=${contractor}&customerId=${SelectedCustomer}`
    );
  },
  getAuditServiceTypes() {
    return this.execute("GET", `/Search/servicetypes`);
  },
  createFavourite(payload) {
    return this.execute("POST", "/Bookmarks", payload);
  },
  sendEmailFinanceReport(payload) {
    return this.execute("POST", "/Notification/financereport", payload);
  },
  sendEmailAuditTool(payload) {
    return this.execute("POST", "/Notification/audittool", payload);
  },
  EmailHistory(type, serviceId, fromdate, todate) {
    return this.execute(
      "GET",
      `/Notification/${type}/history?id=${serviceId}&fromdate=${fromdate}&todate=${todate}`
    );
  },
  getEmailAttachments(itemId, fileId) {
    return this.execute(
      "GET",
      `/Notification/${itemId}/attachment?attachmentId=${fileId}`
    );
  },
  getServiceById(serviceID, userId) {
    return this.execute("GET", `/Search/${serviceID}?userId=${userId}`);
  },
  getServiceOtherPictures(assetID, serviceID) {
    return this.execute(
      "GET",
      `/Search/asset/${assetID}/serviceimages?serviceId=${serviceID}`
    );
  },
  getNotes(serviceID) {
    return this.execute("GET", `/Notes/${serviceID}`);
  },
  updateNote(noteID, updateNotePayLoad) {
    return this.execute("PUT", `/Notes/${noteID}`, updateNotePayLoad);
  },
  deleteNote(noteID, updatedBy) {
    return this.execute("DELETE", `/Notes/${noteID}?updatedBy=${updatedBy}`);
  },
  addNote(addNotePayLoad) {
    return this.execute("POST", "/Notes", addNotePayLoad);
  },
  getInspectionReport(payload) {
    return this.executeDownloadPDF(
      "post",
      `/Search/service/inspectionreport`,
      payload
    );
  },
  getCutomersList() {
    return this.execute("get", `/AppSheet/Customers`);
  },
  //Upload Activation Servcies File
  UploadActivationServcieFile(oemCrId,payload){
    return this.execute("POST", `/ActivationServices/${oemCrId}/upload`,payload);
  },
  getContractorCustomers(contractorId) {
    return this.execute("get", `/Contractor/${contractorId}/customers`);
  },
  UnitInstallationByDayWeek(companyid, fromDate = null, toDate = null, oemCompanyId) {
    return this.execute(
      "get",
      `/Stats/UnitInstallationByDayWeek?companyId=${companyid}&fromDate=${fromDate}&toDate=${toDate}&oemCompanyId=${oemCompanyId}`
    );
  },
  getInstallsBydayExcel(companyId, oemCompanyId){
    return this.execute("get", `/Stats/InstallationsByDay/Download?companyId=${companyId}&oemCompanyId=${oemCompanyId}`);
  },
  getInstallsByWeekExcel(companyId, oemCompanyId){
    return this.execute("get", `/Stats/InstallationsByWeek/Download?companyId=${companyId}&oemCompanyId=${oemCompanyId}`);
  },
};
