import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "../src/assets/styles/main.scss";
import VueApexCharts from "vue-apexcharts";
import VueToast from 'vue-toast-notification';
import VueMask from 'v-mask';
import 'vue-toast-notification/dist/theme-default.css';
Vue.use(VueToast, {
  position: "top",
  duration: 3000,
  dismissible: true,
  queue: true
});
Vue.use(VueMask);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
