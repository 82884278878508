import axios from "axios";
import env from "../config";
//import { userAdmin } from '@/api/mock';
/**
 * Login by username and password
 *
 * @param {String} username user name
 * @param {String} password user password
 */

const client = axios.create({
  baseURL: env.USER_ENDPOINT,
  json: true
});

export default {
  async execute(method, resource, data) {
    // inject the accessToken for each request
    return client({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data;
    });
  },
  // async loginByEmail(username, password) {
  //   try {
  //     let params = { userId: username, password: password };
  //     return this.execute("post", "/Registration/Login", params);
  //   } catch (err) {
  //     console.warn(`[loginByEmail] ${err}`);
  //   }
  // }
  async loginByEmail(username, password) {
    try {
      let params = { userId: username, password: password };
      return this.execute("post", "/Auth/Login", params);
    } catch (err) {
      console.warn(`[loginByEmail] ${err}`);
    }
  },
  async getAuthToken(token) {
    try {
      let payload = {
        id_token: token,
        type: "bearer"
      }
      return this.execute("post", "/token/sso", payload);
    } catch (err) {
      console.warn(`[getAuthToken] ${err}`);
    }
  },
};

/**
 * Get user information by token
 *
 * @param {String} token user token
 */
export const getUserInfo = async token => {
  console.log(`[getUserInfo] token ${token}`);
  let user = {};
  try {
    if (!token) {
      throw new Error("Invalid token");
    }

    // if (token === userAdmin.token) {
    //   user = userAdmin;
    // }
  } catch (err) {
    console.warn(`[getUserInfo] ${err}`);
  }
  return { user };
};
