import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { validateAccess, callback } from "../auth";

Vue.use(VueRouter);

const whiteList = ["/signin", "/forgotpassword", "/ResetPassword", "/loginredirect", "/implicit/callback"];
// const isAuthenticated = (to, from, next) => {
//   if (store.getters.isLoggedIn) {
//     next();
//     return;
//   }
//   next("/login");
// };

const hasPermission = (roles, route) => {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(roles);
  }
  return true;
};

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/",
    name: "Dashboard",
    meta: {
      roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/secured/dashboard/index"),
    // beforeEnter: validateAccess
  },
  {
    path: "/reports",
    name: "Reports",
    meta: {
      roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/secured/reports/index")
  },
  {
    path: "/report",
    name: "Report",
    meta: {
      roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/secured/report/index")
  },
  {
    path: "/supporttools",
    name: "Support Tools List",
    meta: {
      roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/secured/supporttoolslist/index")
  },
  {
    path: "/supporttools/:id",
    name: "Support Tools",
    props: true,
    meta: {
      roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/secured/supporttools/index")
  },
  {
    path: "/group-assignment-tool",
    name: "Grop Assignment Tool",
    meta: {
       roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/secured/GroupAssignmentTool/index")
    // beforeEnter: isAuthenticated
  },
  {
    path: "/hours-update-tool",
    name: "Hours Update Tool",
    meta: {
       roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/secured/HoursUpdateTool/index")
    // beforeEnter: isAuthenticated
  },
  {
    path: "/metadata-update-tool",
    name: "MetaData Update Tool",
    meta: {
       roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/secured/MetadataUpdateTool/index")
    // beforeEnter: isAuthenticated
  },
  {
    path: "/customer-service-list-upload",
    name: "Service List Upload",
    meta: {
       roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/secured/servicelistupload/index")
    // beforeEnter: isAuthenticated
  },
  {
    path: "/vendor_service_list_upload",
    name: "Service List Upload",
    meta: {
       roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/secured/VendorServiceListUpload/index")
    // beforeEnter: isAuthenticated
  },
  {
    path: "/activation-services",
    name: "Activation Services",
    meta: {
      // roles: ["OEM"]
      roles: ["OEM", "Manager", "User"]
    },
    component: () => import("@/components/secured/activationservices/index")
  },

  {
    path: "/360-lite",
    name: "360 Lite",
    meta: {
       roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/secured/360Lite/index"),
    // beforeEnter: validateAccess
    // beforeEnter: isAuthenticated
  },
  {
    path: "/signin",
    name: "Signin",
    meta: {
      roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/unsecured/signin/index")
    // beforeEnter: isAuthenticated
  },
  {
    path: "/loginredirect",
    name: "Login Redirect",
    meta: {
      roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/unsecured/LoginRedirect/index"),
    beforeEnter: validateAccess,
  },
  {
    path: "/implicit/callback",
    name: "Implicit Callback",
    meta: {
      roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: callback,
  },
  // {
  //   path: "/forgotpassword",
  //   name: "Forgot Password",
  //   meta: {
  //     roles: ["Customer", "Contractor","OEM", "Manager", "User"]
  //   },
  //   component: () => import("@/components/unsecured/forgotpassword/index")
  //   // beforeEnter: isAuthenticated
  // },
  {
    path: "/user-profile",
    name: "Profile",
    meta: {
      roles: ["Customer", "Contractor","OEM", "Manager", "User"]
    },
    component: () => import("@/components/unsecured/Profile/index")
    // beforeEnter: isAuthenticated
  },
  // {
  //   path: "/change-password",
  //   name: "Change Password",
  //   meta: {
  //     roles: ["Customer", "Contractor","OEM", "Manager", "User"]
  //   },
  //   component: () => import("@/components/unsecured/ChangePassword/index")
  //   // beforeEnter: isAuthenticated
  // },
  {
    path: "*",
    name: "not-found",
    component: () => import("@/views/AppError.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach(async (to, from, next) => {
  try {
    if (store.getters.token) {
      if (whiteList.includes(to.path)) {
        next({ path: "/" });
      } else {
        if (!store.getters.roles || !store.getters.user) {
          await store.dispatch("LogOut");
          next({ path: "/signin" });
        } else {
          if (!hasPermission(store.getters.roles, to)) {
            next({ path: "/" });
          } else {
            if (to.params.key) {
              to.meta.title = to.params.key;
            }
            next();
          }
        }
      }
    } else {
      if (whiteList.includes(to.path)) {
        next();
      } else {
        next(`/signin`);
      }
    }
  } catch (err) {
    console.warn(`[router.beforeEach]\t${to.path}: ${err}`);
  }
});

export default router;
