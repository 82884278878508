<template>
  <v-menu
    offset-y
    origin="center center"
    :nudge-bottom="10"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-list class="profile-list">
        <v-list-item v-on="on" class="profile-section">
          <v-list-item-avatar>
            <img src="../assets/profile-icon.svg" class="mdi-icon-style-2" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> </v-list-item-title>
            <v-list-item-subtitle>
              <p class="mb-0 username-label">
                {{ name }}
              </p>
              <p class="mb-0 company-label">{{ user }}</p>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <v-list class="pa-0">
      <v-list-item
        v-for="(item, index) in menuitems"
        :key="index"
        ripple="ripple"
        :disabled="item.disabled"
        :target="item.target"
        rel="noopener"
        @click="item.click"
      >
        <v-list-item-action v-if="item.icon == 'mdi-account'">
          <v-avatar size="26px">
            <img v-if="avatar.imageURL" :src="avatar.imageURL" alt="name" />
            <img
              v-else
              src="../assets/profile-icon.svg"
              class="mdi-icon-style-2"
            />
          </v-avatar>
        </v-list-item-action>
        <v-list-item-action v-else>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="menu-title">{{
            item.title
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<style scoped lang="scss">
@import "../assets/styles/variables.scss";
.profile-list {
  padding: 0 !important;
}
.mdi-icon-style {
  font-size: 22px !important;
}
.mdi-icon-style-2 {
  height: 20px;
}
.profile-section {
  padding: 0px;
  width: 210px;
}
.v-menu__content {
  top: 64px !important;
}
.v-list-item__avatar {
  margin-right: 5px !important;
}
.v-list-item__action {
  margin-right: 10px !important;
}
.username-label {
  color: $black-shade-4;
  font-weight: 500;
}
.company-label {
  color: $neutral-gray-7;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.menu-title {
  height: 24px;
}
</style>
<script>
// import apiService from "../api/api.service";
import userService from "../api/user.service";
import { mapGetters } from "vuex";
export default {
  name: "AppProfile",
  data() {
    return {
      result: [],
      isAvatar: false,
      isProfile: false,
      avatar: {
        imageURL: null
      },
      menuitems: [
        {
          icon: "mdi-account",
          title: "Profile",
          click: () => {
            this.$router
              .push({
                path: "/user-profile"
              })
              .catch(() => {});
          }
        },
        // {
        //   icon: "mdi-lock-outline",
        //   title: "Change Password",
        //   click: () => {
        //     this.$router
        //       .push({
        //         path: "/change-password"
        //       })
        //       .catch(() => {});
        //   }
        // },
        {
          icon: "mdi-exit-to-app",
          href: "#",
          title: "Logout",
          click: () => {
            this.logout();
          }
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "name",
      // 'avatar',
      "status"
    ])
  },
  mounted() {
    this.userId = this.$store.getters.userId;
    this.getProfileById();
  },
  methods: {
    logout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/signin");
    },
    async getProfileById() {
      this.isProfile = true;
      try {
        this.result = await userService.getProfile(this.userId);
        if (this.result.image) {
          this.avatar.imageURL = this.result.image;
        }
        this.isProfile = false;
      } catch (err) {
        this.isProfile = false;
        this.$toast.error("Unable to load the report requests" + err);
      }
    }
  }
};
</script>
